/// <reference types="vite-plugin-svgr/client" />
import Logo from "src/components/logo.svg?react"; // Imports the SVG as a component: https://stackoverflow.com/a/70314031/13347795

import * as React from "react";

import {UserModel} from "src/model/userModel";
import {PromiseHandler} from "src/model/promiseHandler"
import {PromiseRenderer} from "src/components/promiseRenderer";

import {Link} from "react-router-dom";
import {Profile} from "src/components/profile";

import { observer } from "mobx-react-lite";

import "src/styles/navbar.css";
import "src/styles/dropdown.css";

interface NavbarProps {
	userHandler: PromiseHandler<UserModel>;
	/** Called when either the "Sign up" or "Log in" button is clicked.
	 * Clicking either button will also automatically redirect to the "/account" page.
	 * @param signUp True if the "Sign up" button is clicked and not the "Log in" button.
	 */
	onAccountClick: (signUp: boolean) => void;
}
export function NavbarView(props: NavbarProps) {
	function LoginRenderer({result}: {result: UserModel}) {
		if (result.isGuest) {
			return <div className="panel">
				<Link to="account" onClick={() => props.onAccountClick(true)}>Sign up</Link>
				<Link to="account" onClick={() => props.onAccountClick(false)}>Log in</Link>
			</div>
		} else {
			return (
				<div className="panel">
					<Link to="account">{"Account: " + props.userHandler.result.name}</Link>
				</div>);
		}
		/* Removing this temporarily. */
		{/* return <div className="dropdown" tabIndex={0}>
			<Profile username={result.name} imagePath="/assets/profile.png" />
			<div className="contents right-align panel vertical shadow-minor">
				<Link to="settings">Settings</Link>
				<Link to="idk">Idk</Link>
				<Link to="signOut">Sign out</Link>
			</div>
		</div>*/}
	}

	return <div className="navbar shadow-minor">
		<Link to="/" className="logo-button">
			<h1 className="no-margin logo-container">
				<Logo />
				<span>YouGuessr</span>
			</h1>
		</Link>
		<nav className="panel">
			<Link to="/hampus-youtube">Play</Link>
			<Link to="/gameSetup">Game setup</Link>
			{/*<Link to="/game">Game</Link>
			<Link to="/account">Account</Link>
			<Link to="/alexander">Alexander</Link>
			<Link to="/david">David</Link>
			<Link to="/hampus">Hampus</Link>
			<Link to="/tindra">Tindra</Link>*/}
		</nav>
		<div className="login-container">
			<PromiseRenderer promiseHandler={props.userHandler} resultComponent={observer(LoginRenderer)} />
		</div>
	</div>;
}
