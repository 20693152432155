import { youtubeModel } from './youtubeModel';
import { makeAutoObservable } from 'mobx';
import { makeObservable } from 'mobx';
import { getRandomVideo, addCountryToVideo, VideoCountry } from "src/modules/youtubeApi";
import { observable, action, makeObservable, makeAutoObservable } from "mobx"

export type Video = {
	channelId: string;
	channelCountry: string;
}

export type youtubeModel = {
	channelCountry: string;
	viewedVideos: Array<Video>;
	getRandomVideos(amount: number): Promise<VideoCountry>;
}

const youtubeModel: youtubeModel = {
	channelCountry: "",
	viewedVideos: [],

	getRandomVideos(amount: number): Promise<VideoCountry> {
		return getRandomVideo(amount).then(addCountryToVideo);
	}
}



class youtubeClass {
	channelCountry = "";
	viewedVideos = []

	getRandomVideos(amount: number): Promise<VideoCountry> { // returns a random country with video
		return getRandomVideo(amount).then(addCountryToVideo)
	}

	addCountryToViewed(video: VideoCountry) { // add video to viewed list
		this.viewedVideos.push(video)
	}

	constructor(youtubeModel: youtubeModel) {
		makeObservable(this, {
			channelCountry: observable,
			viewedVideos: observable,

			getRandomVideos: action,
			addCountryToViewed: action,
		})
	}
}

export { youtubeClass, youtubeModel }
