import {Game} from "./gameModel";
import {User as FirebaseUser, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut } from "firebase/auth";
import { auth } from "src/modules/firebaseApi";
import { observable, action, makeObservable } from "mobx";

export class UserModel {
	/** Copy of user object in Firebase. May be undefined. */
	firebaseUser?: FirebaseUser;
	/** Unique identifier for the user. */
	id?: string;
	/** Display name of the user. */
	name?: string;
	/** Temporary test data, should be removed later. */
	data: number;
	/** Used to see if the model is ready to receive data from Firebase */
	ready: boolean;
	/** Whether this is a logged in user, or a guest user without an account. */
	isGuest = true;
	/** ID of current game that the user is participating in. May be undefined. */
	gameId?: string;

	constructor() {
		makeObservable(this, {
			firebaseUser: observable,
			id: observable,
			name: observable,
			data: observable,
			ready: observable,
			isGuest: observable,
			gameId: observable,

			updateUser: action,
			setData: action,
			setName: action,
			setIsGuest: action,
			setReady: action,
			createAccount: action,
			authenticateAccount: action,
			signOutOfAccount: action
		})
	};

	/** Updates firebaseUser. */
	updateUser(user: FirebaseUser) {
		this.firebaseUser = user;
	};

	/** Updates data. */
	setData(input: number) {
		this.data = input;
	};

	/** Sets the name of the user. */
	setName(input: string) {
		this.name = input;
	}

	/** Sets the guest status of the user. */
	setIsGuest(input: boolean) {
		this.isGuest = input;
	}

	/** Sets the database (Firebase) transfer readiness of the user. */
	setReady(input: boolean) {
		this.ready = input;
	}

	/** Creates a new account. */
	async createAccount(username: string, email: string, password: string) {
		if (username.length > 2) {
			return createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => { 
				updateProfile(userCredential.user, {displayName: username});
			});
		} else {
			return Promise.reject(new Error ("Please enter a username longer than two characters."));
		}
	};

	/** Logs in to an account. */
	async authenticateAccount(email: string, password: string) {
		return signInWithEmailAndPassword(auth, email, password);
	};

	/** Signs out of an account. */
	async signOutOfAccount() {
		signOut(auth);
	};
}

export const user = new UserModel();

export function fetchUser() {
	return new Promise(getUser);
}

export function getUser(): UserModel {
	return user;
}
