import * as React from "react";
import { GameSetup } from "src/views/gameSetupView";
import { useNavigate } from "react-router-dom";
import { SessionProps } from "src/model/model";
import { Game, GameRules } from "src/model/gameModel";
import { timeoutPromise } from "src/modules/test/timeoutPromise";

export function GameSet(props:SessionProps) {
    const navigate = useNavigate(); // Hook for navigation

    function submitSetupACB(event:React.FormEvent){
        event.preventDefault()
        const formData = new FormData(event.target as HTMLFormElement);
        const numberOfRounds = Number(formData.get("numberOfRounds"));
        // Process selected game modes
        const checkedModes = Array.from(formData.entries())
            .filter(([key, value]) => value === "on") // Filter only checked checkboxes
            .map(([key]) => Number(key));

        // props.model.setNumOfRounds(rounds);
        const gameRules = new GameRules();
        const game = new Game("5","123", gameRules);
        const gamePromise = timeoutPromise(1000, game) // kommer ersättas av firebase för att presista
        props.session.gameHandler.handlePromise(gamePromise)
        navigate("/game")

    }

    return(
        <div>
            <GameSetup 
            submitSetup={submitSetupACB}
            />

        </div>
    )
};
