import * as React from "react";

import "src/styles/account.css";

export function SettingsView(props: any) {
    return (
        <main className="container">
            <div className="box">
            <b>Settings</b><br></br>
            <input type="range" min="0" max="20" value={""+props.data} onChange={(evt) => props.changeData(evt.target.value)}/> {/* TODO: Might want to add suspense to avoid the user having to see the value move by itself for a split second. */}
            </div>
        </main>  
    );
}
