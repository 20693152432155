import L from "leaflet"
import { getCountryFromCoordinates, getRandomCountryAndPosition, getCountryFromName } from "src/modules/mapApi";
import { BBox, MultiPolygon, Polygon } from "geojson";

export type Country = {
	name: string,
	flag: string,
	geometry?: Polygon | MultiPolygon,
	bboxes: BBox | BBox[],
	selectedBBox?: BBox
}

export type MapResults = {
	distance: number,
	isInCorrectCountry: boolean
}

export type MapModel = {
	correctCountry: Country | null,
	selectedBBox: BBox | null,
	correctPosition: L.LatLng | null,
	markerPosition: L.LatLng | null,
	markerCountry: Country | null,
	setMarkerPosition(position: L.LatLng, isDebug?: boolean): void,
	setMarkerCountry(isDebug?: boolean): void,
	updateCorrectPosition(position: L.LatLng): void,
	setRandomPosition(): void,
	makeGuess(): void,
	reset(): void,
	results: MapResults
}

const mapModel: MapModel = {

	correctCountry: null,
	correctPosition: null,
	markerPosition: null,
	markerCountry: null,
	selectedBBox: null,
	results: null,

	setMarkerPosition(position: L.LatLng, isDebug: boolean = false): void{
		if (this.results && !isDebug) return alert("Please generate a new country to move the marker"); // alert message is just for debugging
		if (!this.correctPosition && !isDebug) return alert("No country has been generated yet!");
		this.markerPosition = position;
	},

	setMarkerCountry(isDebug: boolean = false): void {
		if ((this.results || !this.markerPosition) && !isDebug) return;
		else this.markerCountry = getCountryFromCoordinates(this.markerPosition);
	},

	updateCorrectPosition(position: L.LatLng): void{
		this.correctPosition = position;
	},

	reset(): void{
		this.results = null;
		this.correctCountry = null;
		this.correctPosition = null;
		this.markerCountry = null;
		this.markerPosition = null;
		this.selectedBBox = null;
	},

	setRandomPosition(): void{
		this.reset();
		const countryAndPosition = getRandomCountryAndPosition()
		this.correctPosition = countryAndPosition.position;
		this.correctCountry = countryAndPosition.country;
		this.selectedBBox = countryAndPosition.selectedBBox;
	},

	makeGuess(): void {
		if (!this.correctPosition) return alert("No position has been generated!");
		if (!this.markerPosition) return alert("No position selected!");
		
		this.results = {
			distance: this.correctPosition.distanceTo(this.markerPosition),
			isInCorrectCountry: this.markerCountry.name === this.correctCountry.name
		}
	},
}

export { mapModel };
