import React, { useState, useEffect } from "react";

function noTimeLeftCB(props) {
	props.endGame()
}


export function Timer(props) {
	const [time, setTime] = useState(20); // Initialize time to 90 seconds
	var audio = new Audio("/audio/humanaudio.mp3");

	function lowerTimeCB(prevTime: number) {
		if (prevTime > 0) {
			return prevTime -1;
		} else {
			props.endGame()
			return 0
		}
	}

	useEffect(() => {

		// audio.play();

		const interval = setInterval(function () {setTime(lowerTimeCB);
		}, 1000); // updates every second

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div>
			{/*
			<audio controls={true} autoPlay={true} id="gameMusic">
			<source src="src/audio/gameMusic.mp3" type="audio/mp3"/>
			??? Tydligen behövs det text här
			</audio>
			*/}

			<div className="timer">
				<h1>{time}</h1>
			</div>

		</div>
	);
};

export default Timer;
