import * as React from "react";

// Component imports
import {YoutubeVideo} from "src/components/youtubeVideo";
import {Button} from "src/components/button";

export function TestGameView(props: React.HTMLAttributes<HTMLDivElement>) {
	return <div className="side-layout" {...props}>
		<div>
			<YoutubeVideo className="shadow-minor" src="https://www.youtube.com/embed/dQw4w9WgXcQ" />
		</div>
		<div className="center-align">
			<section>
				<p><b>QUESTION</b> question QUESTION <i>question</i> question <i>QUESTION</i>???</p>
				<p>Somethin' else</p>
			</section>
			<Button onClick={() => window.alert("CORRECT!")}>Click me!</Button>
			<Button className="red" onClick={() => window.alert("VERY WRONG!")}>Click me!</Button>
			<Button className="green" onClick={() => window.alert("WRONG!")}>Click me!</Button>
		</div>
	</div>;
}
