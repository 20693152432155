import * as React from "react";
import { MapContainer, TileLayer, useMapEvent, Rectangle, Pane, GeoJSON, Polyline } from 'react-leaflet'
import L from "leaflet";
import MapMarker from "src/components/mapMarker";
import { BBox } from "geojson";
import BBoxRectangle from "src/components/bboxRectangle";
import { Button } from "src/components/button";
import MapResultScreen from "src/components/mapResults";

export interface MapControlsViewProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isDebug: boolean;
	onNewPosition: () => void;
	makeGuess: () => void;
}
export default function MapControlsView({isDebug, onNewPosition, makeGuess, className, ...props}: MapControlsViewProps){
	return (<>
		{isDebug ? <Button onClick={onNewPosition}>Generate new position</Button> : null}
		<div className="button-wrapper-cool">
			{/* {...props} adds any other HTML button attributes to the component, including the disabled property. */}
			<Button className={"button-make-guess " + className} onClick={makeGuess} {...props}>Make guess</Button>
		</div>
	</>)
}
