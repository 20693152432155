import * as React from "react";
import {gameModes} from "src/model/gameModeModel";
import {Button} from "src/components/button";
import { GameRules } from "src/model/gameModel";
import { action } from "mobx";

import "src/styles/form.css";


export function GameSetup(props: any) {

	return <main>
		<form id="gameForm" onSubmit={event => props.submitSetup(event)}>
			<fieldset>
			<legend>Game modes</legend>
				<div className="grid-layout">
					<React.Fragment>
					<label>number of rounds</label>
					<input type="range" min="0" max="3" name="numberOfRounds" style={{width:"150px"}}/> 
					</React.Fragment>
					{Array.from(gameModes.entries()).map(([id, gameMode], index) => {
						return <React.Fragment key={index}> {/* React.Fragment is basically a <> tag, I needed to write it out here to add the key property. */}
								<label htmlFor={id.toString()}>{gameMode.title}: </label>
								<input  name={id.toString()}
										id={id.toString()}
										type="checkbox"
										defaultChecked
									/>
						</React.Fragment>
					})}
				</div>
			</fieldset>
			<div className="button-wrapper-cool">
				<Button className="button-cool" type="submit" form="gameForm">Start game</Button>
			</div>
		</form>
	</main>
}

// TODO: QR code invites
// TODO: Hoverable game mode descriptions
// TODO: Small SVG icons next to text input fields
