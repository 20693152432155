/// <reference types="vite-plugin-svgr/client" />
import Logo from "src/components/logo.svg?react"; // Imports the SVG as a component: https://stackoverflow.com/a/70314031/13347795
import * as React from "react";

export function Home() {
	return <main className="main-home">
		<h1 className="center-align">Welcome!</h1>
		<hr />
		<p>Welcome to YouGuessr, a fun game that is easy to play!
			Explore YouTube and learn about videos from across the world.
			Compete with friends on the leaderboard.
		</p>
		<h2>What is <span className="logo-container"><Logo />YouGuessr</span>?</h2>
		<p>Idk to be honest.</p>
	</main>
}
