import * as React from "react";
import L from "leaflet";
import { MapView } from "src/views/mapView";
import { observer } from "mobx-react-lite";
import { MapModel } from "src/model/mapModel";
import { GeoJSON } from "react-leaflet";
import { countryToNameAndFlag, bboxesEqual } from "src/modules/mapApi";
import { isReaction } from "mobx/dist/internal";
import { formatDistance } from "src/modules/mapApi";
import { checkIsBBoxArray } from "src/modules/mapApi";
import { BBox } from "geojson";
import MapControlsView from "src/views/mapControlsView";

export const MapControls = observer(
    function MapControlsPresenter(props: {model: MapModel, isDebug?: boolean}){

        function setNewPositionACB(){
            props.model.setRandomPosition();
        }

        function makeGuessACB(){
            props.model.makeGuess();
        }
        
        return (
            <MapControlsView
            disabled={props.model.markerPosition == null}
            isDebug={props.isDebug}
            onNewPosition={setNewPositionACB}
            makeGuess={makeGuessACB}
            />
        )
    }
)
