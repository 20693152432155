import * as React from "react";
import {observable, reaction} from "mobx";
import {createRoot} from "react-dom/client";
import {session} from "src/model/model";
import {mapModel} from "src/model/mapModel";
import { youtubeModel} from "src/model/youtubeModel";
import {connectToFirebase} from "src/modules/firebaseApi";
import {BrowserRouter} from "react-router-dom"
import {Root} from "src/root";
import { configure } from "mobx"

import "src/styles/style.css";

// Configuring MobX to always enforce actions, since we are using MobX with actions.
configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    //observableRequiresReaction: true,
    //disableErrorBoundaries: true
})

const reactiveSession = observable(session);

import {PromiseHandler} from "src/model/promiseHandler";
const davesModel = new PromiseHandler();

createRoot(document.getElementById('root')!).render( // TODO: Move mapModel and authModel to model.
	<BrowserRouter future={{
		v7_startTransition: true, // This is just some unnecessary boilerplate to get rid of warnings.
		v7_relativeSplatPath: true
	}}>
		<Root
			mapModel={observable(mapModel)}
			youtubeModel={observable(youtubeModel)}
			davesModel={davesModel}
			session={reactiveSession}
		/>
	</BrowserRouter>
);

connectToFirebase(reactiveSession.user, reaction);
