import * as React from "react";

// CSS imports
import "src/styles/game.css";

// View imports
import { MapControls } from "src/presenters/mapControlsPresenter";
import {TestGameView} from "src/views/games/testGameView";
import {MapGameView} from "src/views/games/mapGameView";
import { PromiseRenderer } from "src/components/promiseRenderer"
import Timer from "src/components/timer";
import {YoutubeVideo} from "src/components/youtubeVideo";
import { PromiseHandler } from "src/model/promiseHandler";
import { Video, getVideoFromCoordinates, } from "src/modules/youtubeApi";
import LoadingSpinner from "src/components/loading";
import "src/styles/style.css"

function showSelectedGame(props) {
	//const gameIndex: Number = 1;
	const gameIndex: number = props.gameID;

	const gameViews = {
		0: <TestGameView/>,
		1: <MapGameView model={props.model}/>
		// 2: <CringeGameView />,
		// 3: <QuirkyGameView />,
	};

	if (gameViews[gameIndex] !== undefined && !Number.isNaN(gameIndex)) {
		return gameViews[gameIndex];
	} else {
		return <div>
			404: No games, but unlimited bacon.
		</div>
	};
}

export function Game(props) {
	//console.log(props.session.gameHandler.result)
	// console.log(props.session.gameHandler.result.numberOfRounds) // classic lab :sob:
	const { model, gameID, youtubeModel, session } = props;
	const videoPromiseHandler = new PromiseHandler();

	const [videoData, setVideoData] = React.useState(null);
	const [gameOver, setGameOver] = React.useState(null)

	React.useEffect(() => {
		// getVideoId(); // ladda en video på direkten.
	}, []);

	function setVideoDataACB(video) {
		setVideoData(video);
		console.log(video.id.videoId);
	}
	
	function getVideoId() {
		model.setRandomPosition();
		videoPromiseHandler.handlePromise(
			getVideoFromCoordinates(model.correctPosition),
		)
	}

	function submitGuessACB() {
		model.makeGuess();
		// getVideoId();
	}

	function handleGameOver() {
		setGameOver(true);
		console.log("gameOver")
	}


	const ErrorComponent = ({ error }) => (
		<p className="error">Error: {error.message || error.toString()}</p>
	);
	
	const LoadingComponent = () => <LoadingSpinner/>;

	const NotLoadingComponent = () => <div>No video loaded</div>;

	const ResultComponent = ({ result }) => {
		if (!result) {
			return <div>
				no data :C
			</div>
		}
		console.log(result)
		return (
			<div>
				<YoutubeVideo className="shadow-minor video-youtube" src={
					`https://www.youtube.com/embed/${result.id.videoId}?
					playsinline=1&
					iv_load_policy=3&
					autoplay=1&
					fs=0`} />
			</div>
		)
	};

	if (gameOver) {
		return (
			<div>
				<h1>
					Game Over! 
					L Bozo.
				</h1>
			</div>
		);
	}

	return (
		<main className="game-layout">
			<h1 className="game-timer">
				<Timer model={props.model} endGame={handleGameOver}/>
				<MapControls model={props} />
			</h1>
			<div className="game-content">
				<PromiseRenderer
					promiseHandler={videoPromiseHandler}
					resultComponent={ResultComponent}
					errorComponent={ErrorComponent}
					loadingComponent={LoadingComponent}
					notLoadingComponent={NotLoadingComponent} />

				{showSelectedGame(props) /* har ingen aning hur vi vill skicka in index */}
			</div>
			{/*Tindris lilla test för game settings, datahanteringen */}
			<PromiseRenderer promiseHandler={props.session.gameHandler} resultComponent={(props)=>{ 
				console.log(props.result)
				return <div>testing gameThings</div>
			}}/>
	</main>)
}
