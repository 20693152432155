import * as React from "react";
import { MapContainer, TileLayer, useMapEvent, useMap, Rectangle, Pane, GeoJSON, Polyline } from 'react-leaflet'
import L from "leaflet";
import MapMarker from "src/components/mapMarker";
import { BBox } from "geojson";
import BBoxRectangle from "src/components/bboxRectangle";
import { Button } from "src/components/button";
import MapResultScreen from "src/components/mapResults";
import { useState } from "react";
import { getUnitVector, sumLatLng } from "src/modules/mapApi";

export function MapView(props){

    function ClickListener(): null{
        useMapEvent('click', (e) => {
            props.onMapClick(e.latlng);
        })
        return null
    }

    function MapController(): null{
        const map = useMap();
        if (props.results !== null){
            map.flyToBounds(
                new L.LatLngBounds(
                    props.markerPosition,
                    props.correctPosition
                ),
                {
                    duration: 0.1
                }
            )

            // can be used down the line for UX things
            map.once("moveend", () => {
                props.onFinishZoomAnimation()
            })

        }
        return null
    }

    function createBBoxRectangle(bbox: BBox, index: number, color: string = "blue"){
        return <BBoxRectangle bbox={bbox} color={color} key={index}/>
    }

    return (
        <>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossOrigin=""/>
        <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js" integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossOrigin=""/>
        <MapContainer className="map" center={[20.0, 0]} zoom={2} scrollWheelZoom={true} maxBounds={L.latLngBounds(L.latLng(-90,-180), L.latLng(90,185))} >
            <MapController/>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
            
            <Pane name="geojson" style={{zIndex: 401}}>
                { props.geoJson || null }    
            </Pane>
            {props.markerPosition ? <MapMarker position={props.markerPosition} tooltipText={props.countryName}/> : null }
			<ClickListener/>
            <Pane name="bboxes" style={{zIndex: 400}}>
                { props.renderDebugInformation ? props.bboxes?.map((bbox, index) => createBBoxRectangle(bbox, index)) : null}
                { props.weightedBBox && props.renderDebugInformation ? <BBoxRectangle bbox={props.weightedBBox} color={"red"}/> : null }
            </Pane>
            { props.correctPosition && props.results ? <MapMarker position={props.correctPosition} tooltipText={props.correctCountryName}/> : null }
            { props.results ? <Polyline positions={[props.markerPosition, props.correctPosition]} dashArray={[4, 4]}/> : null }
		</MapContainer>
        
        </>
    )
}