import {JSXElementConstructor} from "react";

/** Static metadata about a specific game mode. */
export interface GameMode {
	/** The name of the game mode. */
	title: string,
	/** A description of how the game mode works. */
	description?: string,
	/** Length in seconds of a round using this game mode. This may later be multiplied by a RoundLength modifier.
	 * @todo Make non nullable
	 */
	length: number,
	/** The component used to render this game mode.
	 * @todo Make non nullable
	 * @todo Add correct parameters
	 */
	component?: JSXElementConstructor<{}>
}
/** Contains all static metadata about all game modes, mapped by a number representing their gameModeId.
 * The gameModeIds are all different powers of 2 (represented in binary with a single bit flipped), and can thus be used as flags. See {@link https://stackoverflow.com/a/39359953/13347795}.
 * @todo Figure out how to make readonly.
 */
export const gameModes = new Map<number, GameMode>([ // IMPORTANT: Make sure all ids are different powers of 2.
	[1, {title: "Country guesser", length: 30}],
	[2, {title: "Popularity estimator", length: 20}],
	[4, {title: "Gender majority", length: 15}]
]);
