import * as React from "react";
import { getCountryFromChannelId, fetchSingleVideo, getRandomVideo,addCountryToVideo,VideoCountry} from "src/modules/youtubeApi";
import {YoutubeVideo} from "src/components/youtubeVideo";
import LoadingSpinner from "src/components/loading";
import { Button } from "src/components/button";
import { PromiseHandler } from "src/model/promiseHandler";
import { PromiseRenderer } from "src/components/promiseRenderer";


let viewed_videos: Array<string> = []

export function TindrasFantasticTest() {
	const videoPromiseHandler = new PromiseHandler(getRandomVideo(50).then(addCountryToVideo));

	function getVideoId(){
		videoPromiseHandler.handlePromise(getRandomVideo(50).then(addCountryToVideo));
	}

	const ResultComponent = ({ result }: { result: VideoCountry}) => {
		if (!viewed_videos.includes(result.videoId)) {
			viewed_videos.push(result.videoId);
		} else {
			if (viewed_videos.length >= 50) {
				console.error("All videos viewed or none available.");
				return <ErrorComponent error = "All videos viewed or none available."/>;
			}
			getVideoId();
		}
		return(
		<div>
			<YoutubeVideo className="shadow-minor" src={"https://www.youtube.com/embed/"+ result.videoId} />
			{result.country}
		</div>)
	};

	const ErrorComponent = ({ error }: { error: any }) => (
	<p className="error">Error: {error.message || error.toString()}</p>
	);

	const LoadingComponent = () => <LoadingSpinner/>;

	const NotLoadingComponent = () => <div>No video loaded</div>;

	return <main>
		<h1>Tindra's Fantastic Test Page</h1>
		<hr />
		<p>It's perfectly <i>reasonable</i>!</p>
		<div>
		<PromiseRenderer
        promiseHandler={videoPromiseHandler}
        resultComponent={ResultComponent}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
        notLoadingComponent={NotLoadingComponent}
      />
	  	<Button className="barbie-pink" onClick ={getVideoId}>New video &lt;3</Button>
		</div>

	</main>;
}

