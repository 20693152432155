import {PromiseHandler} from "./promiseHandler";
import {Game} from "./gameModel";
import {CountryGuesser, PopularityEstimator} from "./roundModel";
import {UserModel, fetchUser, getUser} from "./userModel";
import {AuthModel, getAuthModel} from "./authModel";


import {timeoutHandler} from "src/modules/test/timeoutPromise";
import {mapModel} from "./mapModel";

/** Object representing the current session. Contains all data needed for this client. */
export interface Session {
	/** Promise containing the current game. Use gameHandler.result to get the Game object whenever the promise resolves. See {@link Game} and {@link PromiseHandler}. */
	gameHandler: PromiseHandler<Game>;
	/** Promise containing the current user. Use userHandler.result to get the Game object whenever the promise resolves. See {@link User} and {@link PromiseHandler}. */
	userHandler: PromiseHandler<UserModel>;
	user: UserModel,
	auth: AuthModel
}

export interface SessionProps {
	session: Session;
}

export const session: Session = {
	gameHandler: timeoutHandler(1600, null),
	userHandler: timeoutHandler(1, getUser()), // Creates problems.
	user: getUser(), // Test, please ignore.
	auth: getAuthModel()
};
