import * as React from "react";
import { observer } from "mobx-react-lite";
import { AuthView } from "src/views/authView";
import { SettingsView } from "src/views/settingsView";
import { AuthModel } from "src/model/authModel";
import { UserModel } from "src/model/userModel";

const Auth = observer(
    function AuthComponent(props: {user: UserModel, model: AuthModel}) {
        return (
            <div>
                <AuthView 
                    signOut={signOutACB}
                    submitForm={submitFormACB}
                    toggleSignUp={toggleSignUpACB}
                    errorOccurred={props.model.error}
                    signUp={props.model.signUp}
                    errorMessage={props.model.errorMessage}
                    user={props.user.firebaseUser}
                />
                {props.user.firebaseUser && 
                    <SettingsView 
                        changeData={(input: number) => props.user.setData(input)} 
                        data={props.user.data} 
                        user={props.user.firebaseUser}
                    />
                }
            </div>
        );

        // Asynchronous callback for submitting the log-in or registration form
        function submitFormACB(event: Event) {
            event.preventDefault();
            if (props.model.signUp) {
                props.user.createAccount(event.target[0].value, event.target[1].value, event.target[2].value)
                .catch((error: Error) => {props.model.displayError(error);});
            } else {
                props.user.authenticateAccount(event.target[0].value, event.target[1].value)
                .catch((error: Error) => {props.model.displayError(error);});
            }
        }

        // Asynchronous callback for signing out
        function signOutACB() {
            props.user.signOutOfAccount()
            .catch((error: Error) => {props.model.displayError(error);});
        }

        // Asynchronous callback for toggling form mode (login/registration)
        function toggleSignUpACB() {
            props.model.toggleSignUp();
        }
    }
);

export { Auth }
