import * as React from "react";
import { YoutubeVideoResult, getVideoLocation, getVideoFromCoordinates} from "src/modules/youtubeApi";
import { YoutubeVideo } from "src/components/youtubeVideo";
import LoadingSpinner from "src/components/loading";
import { Button } from "src/components/button";
import { PromiseHandler } from "src/model/promiseHandler";
import { PromiseRenderer } from "src/components/promiseRenderer";
import { GuessMap } from "src/presenters/mapPresenter";
import { MapControls } from "src/presenters/mapControlsPresenter";
import L from "leaflet";
import "src/styles/game.css";
import MapResultsView from "../mapResultsView";
import { countryToNameAndFlag, formatDistance, getCountryFromName } from "src/modules/mapApi";
import { MapModel } from "src/model/mapModel";

import {observer} from "mobx-react-lite"; // Needed for reactivity.

const videoPromiseHandler = new PromiseHandler(); // Component state needs to be here when using observer().

export const HampusYoutubeTest = observer((props: {model: MapModel}) => {
	function getVideoId(){
		props.model.setRandomPosition()
		videoPromiseHandler.handlePromise(getVideoFromCoordinates(props.model.correctPosition));
		/* TODO: Make it so that props.model.correctPosition gets updated to the recordingLocation of the video */

		// videoPromiseHandler.handlePromise(getVideoLocation("AmGZvlnhs84"));
		// const recordingDetails = (videoPromiseHandler.result as any).items[0].recordingDetails
		// props.model.updateCorrectPosition(new L.LatLng(recordingDetails.location.latitude, recordingDetails.location.longitude))
	}

	const ResultComponent = ({ result }: { result: YoutubeVideoResult }) => {
		return (
			
			<YoutubeVideo className="shadow-minor video-youtube" src={
				`https://www.youtube.com/embed/${result.id.videoId}?
				playsinline=1&
				iv_load_policy=3&
				autoplay=1&
				fs=0`} 
				width={Math.round(500*16/9)}
				height={500}/>
		)
	};

	const ErrorComponent = ({ error }: { error: any }) => (
	<p className="error">Error: {error.message || error.toString()}</p>
	);

	const LoadingComponent = () => <LoadingSpinner/>;

	const NotLoadingComponent = () => <div>No video loaded</div>;
	// console.log(`New video: ${props.model.correctPosition !== null && props.model.result === null}`);
	return <main className="geoguessr-background">
		<h1>Youtube Testing Page</h1>
		<hr />
		<div className="game-container">
			<div className="game-map-video-wrapper">
				<div className="game-video-wrapper">
				<PromiseRenderer
				promiseHandler={videoPromiseHandler}
				resultComponent={ResultComponent}
				errorComponent={ErrorComponent}
				loadingComponent={LoadingComponent}
				notLoadingComponent={NotLoadingComponent}
				/>
				{/*<YoutubeVideo src={
					`https://www.youtube.com/embed/AmGZvlnhs84?
					playsinline=1&
					iv_load_policy=3&
					fs=0`} 
					width={Math.round(500*16/9)}
					height={500}/> */}
					{/* <LoadingSpinner/> */}
			</div>
				<GuessMap model={props.model} isDebug={false}/>
				{/* <GuessMap model={
					{...props.model, 
					correctPosition: new L.LatLng(0, 0), 
					correctCountry: getCountryFromName("Sweden")}
					} 
					isDebug={true}/>{/* for debugging purposes */}
			</div>
			<div className="game-information">
				<div className="game-controls">
					{(videoPromiseHandler.result != null && props.model.results == null) &&
						<MapControls model={props.model} isDebug={false}/>
					}

					{(videoPromiseHandler.result == null || props.model.results != null) && 
						<div className="button-wrapper-cool">
							<Button className="barbie-pink" onClick={getVideoId}>New video &lt;3</Button>
						</div>
					}
				</div>
				{ /* TODO: Make this disappear again when you press the button for getting a new video */
				props.model.results !== null ? 
				<MapResultsView
				isCountryCorrect={props.model.correctCountry.name === props.model.markerCountry.name}
				correctCountryText={countryToNameAndFlag(props.model.correctCountry)}
				guessCountryText={countryToNameAndFlag(props.model.markerCountry)}
				distanceText={formatDistance(props.model.results.distance)}
				/>
				: null
			}
			</div>
		</div>
		
	</main>;
});
