import * as React from "react";
import {observer} from "mobx-react-lite";
import {PromiseHandler} from "src/model/promiseHandler";
import LoadingSpinner from "src/components/loading";

interface PromiseRendererProps<T> {
	/** The {@link PromiseHandler} that will be used when determining the state that should be rendered. */
	promiseHandler: PromiseHandler<T>;
	/** Component that will be rendered when {@link promiseHandler} resolves. */
	resultComponent: React.JSXElementConstructor<{result: T}>;
	/** Component that will be rendered when {@link promiseHandler} fails for some reason. Defaults to a paragraph tag with error text inserted. */
	errorComponent?: React.JSXElementConstructor<{error: any}>;
	/** Component that will be rendered when {@link promiseHandler} is loading. Defaults to {@link LoadingSpinner}. */
	loadingComponent?: React.JSXElementConstructor<{}>;
	/** Component that will be rendered when {@link promiseHandler} hasn't begun loading yet. Defaults to null. */
	notLoadingComponent?: React.JSXElementConstructor<{}>;
}
/**
 * Takes a {@link PromiseHandler} and renders one of the specified components depending on its state.
 */
export const PromiseRenderer = observer(<T,>({
	promiseHandler,
	resultComponent,
	errorComponent = (props) => <p className="error">{(props.error.message || props.error).toString()}</p>,
	loadingComponent = () => <LoadingSpinner />,
	notLoadingComponent = () => null
}: PromiseRendererProps<T>) => {

	if (promiseHandler.promise == null) {return React.createElement(notLoadingComponent);}
	if (promiseHandler.error != null) {return React.createElement(errorComponent, {error: promiseHandler.error});}
	if (promiseHandler.result != null) {return React.createElement(resultComponent, {result: promiseHandler.result});}
	return React.createElement(loadingComponent)
})
