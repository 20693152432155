import * as React from "react";
import {Marker, Tooltip} from 'react-leaflet';
import L from "leaflet";

type MapMarkerAttributes = {
	position: L.LatLng,
	tooltipText?: string,
	icon?: L.Icon
}

var correctLocationIcon = L.icon({
	iconUrl: 'src/components/logo.svg'
})

export default function MapMarker(props: MapMarkerAttributes){
	return (
		<Marker position={props.position}>
			{props.tooltipText ? <Tooltip>{props.tooltipText}</Tooltip> : null}
		</Marker>
	)
}