import * as React from "react";
import { Rectangle } from 'react-leaflet';
import L from "leaflet";
import { BBox } from "geojson";

type BBoxRectangleAttributes = {
	bbox: BBox,
	color?: string
}

export default function BBoxRectangle(props: BBoxRectangleAttributes){
	return (<Rectangle 
		bounds={
			new L.LatLngBounds(
				new L.LatLng(props.bbox[1], props.bbox[0]), 
				new L.LatLng(props.bbox[3], props.bbox[2])
			)}
			pathOptions={{color: props.color || null}}
		/>);
}