import * as React from "react";
import L from "leaflet";
import { MapView } from "src/views/mapView";
import { observer } from "mobx-react-lite";
import { MapModel } from "src/model/mapModel";
import { GeoJSON } from "react-leaflet";
import { countryToNameAndFlag, bboxesEqual } from "src/modules/mapApi";
import { isReaction } from "mobx/dist/internal";
import { formatDistance } from "src/modules/mapApi";
import { checkIsBBoxArray } from "src/modules/mapApi";
import { BBox } from "geojson";

let click_noise = new Audio("/audio/click.mp3");

export const GuessMap = observer(
    function Map(props: {model: MapModel, isDebug?: boolean}){

        function setMarkerACB(latlng: {lat: number, lng: number}){
			//click_noise.play();
            const coords: L.LatLng = new L.LatLng(latlng.lat, latlng.lng).wrap();
            props.model.setMarkerPosition(coords, props.isDebug);
            props.model.setMarkerCountry(props.isDebug);
        }

        function setNewPositionACB(){
            props.model.setRandomPosition();
        }

        function makeGuessACB(){
            props.model.makeGuess()
        }

        function finishZoomACB(){
            console.log("zoom finished");
        }

        // The reactivity for the GeoJSON data seems to not work correctly if I move this to mapView
        function GeoJSONData(){
            return props.model.markerCountry?.geometry ? <GeoJSON data={props.model.markerCountry.geometry} style={function(){return {color: "var(--color-strong)"}}}/> : null
        }

        function filterBBoxes(){
            if (props.model.selectedBBox){
                return checkIsBBoxArray(props.model.correctCountry.bboxes) ? (props.model.correctCountry?.bboxes as BBox[]).filter(bbox => {
                    return !bboxesEqual(bbox, props.model.selectedBBox)
                }) : null
            }
            return null
        }

        return (
            <MapView
            onMapClick={setMarkerACB}
            markerPosition={props.model.markerPosition}
            markerCountry={props.model.markerCountry}
            countryName={props.model.markerCountry ? countryToNameAndFlag(props.model.markerCountry) : null}
            geoJson={<GeoJSONData/>}
            bboxes={filterBBoxes()}
            weightedBBox={props.model?.selectedBBox}
            onNewPosition={setNewPositionACB}
            correctPosition={props.model?.correctPosition}
            correctCountryName={props.model.correctCountry ? countryToNameAndFlag(props.model.correctCountry) : null}
            renderDebugInformation={props.isDebug}
            makeGuess={makeGuessACB}
            results={props.model.results}
            distanceText={formatDistance(props.model?.results?.distance)}
            onFinishZoomAnimation={finishZoomACB}
            />
        )
    }
);
