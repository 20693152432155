import * as React from "react";

// Component imports
import {YoutubeVideo} from "src/components/youtubeVideo";
import {Button} from "src/components/button";

import { GuessMap } from "src/presenters/mapPresenter";

export function MapGameView(props) {
	return <div>
	<GuessMap model={props.model}/>
	</div>
}
