import * as React from "react";
import {PromiseHandler} from "src/model/promiseHandler";
import {PromiseRenderer} from "src/components/promiseRenderer";
import {timeoutPromise} from "src/modules/test/timeoutPromise";

interface DavesFantasticTestProps {
	davesModel: PromiseHandler<string[]>,
	number: number,
	name?: string
}

export function DavesFantasticTest({davesModel, number, name}: DavesFantasticTestProps) {
	const newNumber = number * 3
	return <main>
		<h1>Dave's Fantastic Test Page</h1>
		<hr />
		<p>It's great!</p>
		<p><b>Your magic number is</b>: {newNumber}</p>
		{name && <p><b>Name</b>: {name}</p>}
		<img src="/assets/burn.gif" />
		<div className="panel">
			<a>Thing 1</a>
			<a>Thing 2</a>
			<a>Thing 3</a>
		</div>
		{/*<button onClick={() => model.decrement()}> - </button>
		<span>{model.counter}</span>
		<button onClick={() => model.increment()}> + </button>*/}

		<button onClick={() => davesModel.handlePromise(timeoutPromise(1000, ["Thing", "Another thing"]))}>Short promise</button>
		<button onClick={() => davesModel.handlePromise(timeoutPromise(3000, ["Slow thing", "AAAA"]))}>Long promise</button>
		<button onClick={() => davesModel.handlePromise(timeoutPromise(2000, null, new Error("It went wrong :(")))}>Error promise</button>

		<PromiseRenderer
			promiseHandler={davesModel}
			resultComponent={(props) => <div>
				<h2>Results:</h2>
				<ul>
					{props.result.map((element, index) => <li key={index}>{element}</li>)}
				</ul>
			</div>}
			notLoadingComponent={() => <p>No data</p>}
		/>
	</main>;
}
