import {PromiseHandler} from "src/model/promiseHandler";

/**
 * Creates a promise that will resolve with given results after a set amount of time. Used for testing purposes.
 * @param timeout Amount of milliseconds that will pass before promise is resolved.
 * @param result Promised results. The type of this will be the type in the promise.
 * @param error If specified, the promise will instead be rejected (resulting in an error).
 * @returns A promise that will resolve after a set amount of time.
 */
export function timeoutPromise<T>(timeout: number, result?: T, error?: Error): Promise<T> {
	// Creates a promise that resolves with a predefined result after a set delay.
	// https://stackoverflow.com/a/39496056/13347795
	return new Promise<T>((resolve, reject) => setTimeout(() => {
		if (error != null) {
			reject(error);
		}
		resolve(result)
	}, timeout))
}

/**
 * Creates a {@link PromiseHandler} with a promise that will resolve after a set amount time. See {@link timeoutPromise}.
 * @param timeout Amount of milliseconds that will pass before promise is resolved.
 * @param result Promised results. The type of this will be the type in the promise.
 * @param error If specified, the promise will instead be rejected (resulting in an error).
 * @returns A {@link PromiseHandler} with a promise that will resolve after a set amount of time.
 */
export function timeoutHandler<T>(timeout: number, result?: T, error?: Error): PromiseHandler<T> {
	return new PromiseHandler(timeoutPromise(timeout, result, error))
}
