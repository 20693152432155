import * as React from "react";
import {NavbarView} from "src/views/navbarView";
import {SessionProps} from "src/model/model";

export function Navbar({session}: SessionProps) {
	return <NavbarView
		userHandler={session.userHandler}
		onAccountClick={(signUp) => {
			// TODO: Use callback here.
			session.auth.setSignUp(signUp); // Something like this? -A
		}}
	/>
}
