import * as React from "react";

interface YoutubeVideoProps extends React.IframeHTMLAttributes<HTMLIFrameElement> { // Inherits all usual props for an iframe element.
	src: string // Makes src prop required.
}

export function YoutubeVideo({src, ...props}: YoutubeVideoProps) {
	return <iframe
		width={560}
		height={315}
		src={src}
		title="YouTube video player"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
		referrerPolicy="strict-origin-when-cross-origin"
		allowFullScreen={true}
		{...props} // Adds all additional props, overwriting existing ones if needed.
	/>;
}
