import * as React from "react";
import LoadingSpinner from "src/components/loading";
import { action } from "mobx";

import "src/styles/account.css";

export function AuthView(props: any) {
    if (props.user == undefined) {
        return (
            <main className="container">
                <div className="box">
                    <h2>{props.signUp ? "Sign up" : "Log in"}</h2>

                    <form onSubmit={action(values => props.submitForm(values))} id="accountForm">
                        { props.signUp && 
                            <div>
                                <input placeholder="Username"/><br></br>
                            </div>
                        }
                        <input type="email" placeholder="Email"/><br></br>
                        <input type="password" placeholder="Password"/><br></br>
                        <input className="login" type="submit" value={props.signUp ? "Sign up" : "Log in"}></input>
                    </form>
                
                    <hr className="line"/>

                    {props.errorOccurred &&
                        <div className="error">
                            {props.errorMessage}
                        </div>
                    }

                    <h6>{props.signUp ? "Already have an account?" : "Don't have an account?"}</h6>
                    <input type="button" onClick={action(() => props.toggleSignUp())} value={props.signUp ? "Log in to existing account" : "Create new account"}></input>
                </div>
            </main>
        );
    } else if (props.user) {
        return (
        <main className="container">
            <div className="box">
                <p>Currently signed in as <b>{props.user.displayName}</b>.</p><br></br> {/* Problem: Username will not be shown when an account has just been created. */}
                <input type="button" onClick={action(() => props.signOut())} value="Sign out"></input>
            </div>
        </main>
        );
    } else {
        return (<LoadingSpinner/>);
    }
}