import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "src/modules/firebaseConfig";
import { user, User } from "src/model/userModel";

// Initialisation
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

const userPath = "users/";
const gamePath = "games/";

// Functions

// Returns data from the model for the database
function modelToDb(model: User) {
    return { userData: model.data };
}

// Sets the model data to data given by the database
function dbToModel(dbData: any, model: User) {
    model.setData(dbData.userData); // BUG: userData is sometimes null, which causes error in console.
}

// Saves to model data to the Firebase database
function saveToFirebase(model: User) {
    if (model.ready && model.firebaseUser) {
        set(ref(db, userPath + model.firebaseUser.uid), modelToDb(model));
    }
}

// Sends the Firebase database data to the model data
function readFromFirebase(model: User) {
    if (model.firebaseUser) {
        model.setReady(false);
        return (
            get(ref(db, userPath + model.firebaseUser.uid))
            .then((snapshot) => dbToModel(snapshot.val(), model))
            .then(() => model.setReady(true))
        );
    }
}

// Connects to Firebase and waits for important changes
function connectToFirebase(model: User, watchFunction: Function) {
    readFromFirebase(model);
    watchFunction(
        () => [model.data, model.firebaseUser], // Checking for important changes
        () => saveToFirebase(model) // Side effect, saving to Firebase
    );
}

auth.onAuthStateChanged((current_user) => {
    if (current_user) {
        user.updateUser(current_user);
        user.setName(user.firebaseUser.displayName);
        user.setIsGuest(false);
        readFromFirebase(user);
    } else {
        user.updateUser(undefined);
        user.setIsGuest(true);  // Quick-fix.
    }
  });

export { auth, saveToFirebase, readFromFirebase, connectToFirebase };
