import {Round} from "./roundModel";
import {gameModes} from "./gameModeModel";
import { makeObservable, observable, action} from "mobx";

/** Enum representing round lengths. The enum values are float numbers that can be multiplied by the usual round length, except for Infinite which is represented by 0 and must be handled separately. */
export enum RoundLength { // These numbers are multiplited by the original round durations.
	Short = 0.5,
	Normal = 1.,
	Long = 2.,
	Infinite = 0. // Edge case, must be handled separately.
}

/** Object storing the rules in a game */
export class GameRules {
	/** Integer representing the total number of rounds in this game. */
	@observable numberOfRounds: number = 5;
	/** Enum representing how long the rounds of the game are. See {@link RoundLength}. */
	@observable roundLength: RoundLength = RoundLength.Normal;
	/** Flags representing which gameModes are enabled. See {@link gameModes} and {@link GameMode}.
	 * The number is the sum of different GameMode ids combined through the bitwise OR operator |, so that each bit determines if a specific game mode is included.
	 * As an example, combining the first (0) and fourth (3) game mode results in: (2^0)|(2^3) = 1|8 = 0001|1000 = 1001 = 9
	 * To check if a specific gameMode is included, use bitwise AND operator & like this: (gameModes & GameMode.Second) === GameMode.Second -> (1001 & 0010) === 0010 -> 0000 === 0010 -> false
	 * Here's how the flags work: {@link https://stackoverflow.com/a/39359953/13347795}
	 */
	@observable gameModeFlags: number = 7;

	@action
	setNumOfRounds(input:number){
		this.numberOfRounds = input;
	}

	constructor(){
		makeObservable(this)
		
	}
}

/** Object containing all information about the game being set up or in progress. */
export class Game {
	/** Unique identifier for the game. */
	@observable id: string;
	/** ID of the user hosting the game. */
	@observable hostUserId: string;
	/** An array containing the IDs of each participating user. */
	@observable userIds: string[] = []; // does this include the host?
	/** An object containing the game rules. See {@link GameRules}. */
	gameRules: GameRules;
	/** An array containing the data for each round of the game. See {@link Round}. */
	rounds: Round[] = [];
	/** Index of current round in {@link Game["rounds"]}. */
	roundIndex: number = 0;

	constructor(id: string, hostUserId: string, gameRules: GameRules){
		this.id = id;
		this.hostUserId = hostUserId;
		this.gameRules = gameRules;
		makeObservable(this)
	}
}
