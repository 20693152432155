import * as React from "react";
import {Routes, Route, useLocation} from "react-router-dom";

// Component imports
import {Navbar} from "src/presenters/navbarPresenter";

// Page imports
import {Home} from "src/views/homeView";
import {Game} from "src/presenters/gamePresenter";
import { GameSet } from "./presenters/gameSetupPresenter";
import {Auth} from "src/presenters/authPresenter";
import {Error} from "src/views/errorView";
import {AlexandersFantasticTest} from "src/views/test/alexandersFantasticTest";
import {DavesFantasticTest} from "src/views/test/davesFantasticTest";
import {HampusYoutubeTest} from "./views/test/hampusYoutubeTest";
import {HampusFantasticTest} from "src/views/test/hampusFantasticTest";
import {TindrasFantasticTest} from "src/views/test/tindrasFantasticTest";

export function Root(props) {
	function Error404Body() {
		return <>
			<p>The page you requested at <code>{useLocation().pathname}</code> could not be found.</p>
			<p>Terribly sorry!</p>
			<img src="/assets/scaredhamster.png" alt="Blurry photo of a hamster with a scared expression." />
			<p>
				<i>Best regards,</i><br />
				<b>The YouGuessr&reg; Team</b>
			</p>
		</>
	}

	return <>
		<Navbar session={props.session} />
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/game" element={<Game model={props.mapModel} gameID={1} youtubeModel={props.youtubeModel} session={props.session}/>} />
			<Route path="/gameSetup" element={<GameSet session={props.session} />} />
			<Route path="/account" element={<Auth model={props.session.auth} user={props.session.user}/>}/>
			<Route path="/alexander" element={<AlexandersFantasticTest model={props.authModel}/>} />
			<Route path="/david" element={<DavesFantasticTest davesModel={props.davesModel} number={23} />} />
			<Route path="/hampus" element={<HampusFantasticTest model={props.mapModel}/>} />
			<Route path="/tindra" element={<TindrasFantasticTest />} />
			<Route path="/hampus-youtube" element={<HampusYoutubeTest model={props.mapModel}/>}/>
			<Route path="*" element={
				<Error code={404} title={"Not Found"} body={<Error404Body/>}/>
			}/>
		</Routes>
	</>;
}
