import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", viewBox: "0 0 256 256", "sodipodi:docname": "logo.svg", "inkscape:version": "1.3.2 (091e20e, 2023-11-25, custom)", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview9", pagecolor: "#ffffff", bordercolor: "#000000", borderopacity: 0.25, "inkscape:showpageshadow": 2, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#d1d1d1", "inkscape:zoom": 2.8284271, "inkscape:cx": 16.617009, "inkscape:cy": 158.92225, "inkscape:window-width": 2560, "inkscape:window-height": 1377, "inkscape:window-x": -8, "inkscape:window-y": -8, "inkscape:window-maximized": 1, "inkscape:current-layer": "Layer_1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs6" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "linearGradient5", "inkscape:collect": "always" }, /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#da6a61",
  stopOpacity: 1
}, offset: 0, id: "stop7" }), /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#c3362f",
  stopOpacity: 1
}, offset: 1, id: "stop8" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "linearGradient11", "inkscape:collect": "always" }, /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#da6a61",
  stopOpacity: 1
}, offset: 0, id: "stop11" }), /* @__PURE__ */ React.createElement("stop", { style: {
  stopColor: "#c3362f",
  stopOpacity: 1
}, offset: 1, id: "stop12" })), /* @__PURE__ */ React.createElement("style", { id: "style1" }, ".cls-1{fill:url(#BackgroundGradient);}.cls-2{fill:url(#InnerBorderGradient);}.cls-3{fill:url(#linear-gradient);}.cls-4{fill:#f03;}"), /* @__PURE__ */ React.createElement("linearGradient", { id: "BackgroundGradient", x1: 128, y1: 53.417, x2: 128, y2: 254.472, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0,-0.54252825)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, stopColor: "#4899d0", id: "stop1" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#1975c8", id: "stop2" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "InnerBorderGradient", x1: 128, y1: 52, x2: 128, y2: 256, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0,-0.54252825)" }, /* @__PURE__ */ React.createElement("stop", { offset: 4e-3, stopColor: "#60a7d7", id: "stop3", style: {
  stopColor: "#ffffff",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#3182ce", id: "stop4" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "linear-gradient", x1: 128, y1: 52, x2: 128, y2: 256, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0,-0.54252825)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, stopColor: "#3781b4", id: "stop5" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#2e6c97", id: "stop6" })), /* @__PURE__ */ React.createElement("linearGradient", { "inkscape:collect": "always", xlinkHref: "#linearGradient11", id: "linearGradient13", x1: 130.27605, y1: 44.43692, x2: 126.88066, y2: 177.03508, gradientUnits: "userSpaceOnUse" }), /* @__PURE__ */ React.createElement("linearGradient", { "inkscape:collect": "always", xlinkHref: "#linearGradient5", id: "linearGradient8", x1: 292.54333, y1: 24.557301, x2: 292.54333, y2: 201.11784, gradientUnits: "userSpaceOnUse" })), /* @__PURE__ */ React.createElement("g", { id: "g5", style: {
  fill: "url(#linearGradient13)",
  fillOpacity: 1
}, transform: "translate(-164.67419,2.162428)" }, /* @__PURE__ */ React.createElement("path", { id: "rect5", style: {
  fill: "url(#linearGradient8)",
  fillOpacity: 1,
  strokeWidth: 0.949169
}, d: "m 292.54333,24.5573 c 0,0 -80.02033,-0.0013 -99.82031,5.388671 -11.02999,2.909997 -19.62977,11.390089 -22.75977,22.330078 -5.28999,19.419981 -5.28906,60.380861 -5.28906,60.380861 0,0 -9.3e-4,41.23009 5.28906,60.83007 3.13,10.67999 11.72978,19.33008 22.75977,22.33008 12.95999,3.47 51.73096,4.66984 76.96094,5.08984 13.30998,0.21 22.85937,0.21094 22.85937,0.21094 0,0 9.67111,-7e-4 23.1211,-0.2207 25.28997,-0.42 63.97899,-1.62008 76.95898,-5.08008 11.19999,-3 19.63008,-11.65009 22.58008,-22.33008 5.46999,-19.59998 5.4707,-60.83007 5.4707,-60.83007 0,0 -7.1e-4,-40.96088 -5.4707,-60.380861 C 412.25349,41.33606 403.8234,32.855968 392.62341,29.945971 372.73343,24.555977 292.54333,24.5573 292.54333,24.5573 Z" })), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 1.00157
}, id: "path10", cx: 128, cy: 115, rx: 33.764347, ry: 33.861282 }), /* @__PURE__ */ React.createElement("path", { className: "cls-1", d: "m 128,51.457472 c -35.36,0 -64,28.64 -64,63.999998 0,21.04 22.66,66.61 41.01,100.07 12.5,22.77 22.99,39.93 22.99,39.93 0,0 10.5,-17.16 22.99,-39.94 18.35,-33.46 41.01,-79.02 41.01,-100.06 0,-35.359998 -28.64,-63.999998 -64,-63.999998 z m 0,42 c 12.16,0 22,9.839998 22,21.999998 0,12.16 -9.84,22 -22,22 -12.16,0 -22,-9.84 -22,-22 0,-12.16 9.84,-21.999998 22,-21.999998 z", id: "path7", style: {
  fill: "url(#BackgroundGradient)"
} }), /* @__PURE__ */ React.createElement("path", { className: "cls-2", d: "m 128,59.457472 c 30.878,0 56,25.121 56,55.999998 0,14.981 -14.214,49.15 -40.025,96.214 -6.133,11.185 -11.816,21.06 -15.974,28.153 -4.157,-7.092 -9.84,-16.964 -15.977,-28.143 C 86.214,164.61847 72,130.44547 72,115.45847 72,84.579472 97.121,59.458472 128,59.458472 m 0,85.998998 c 16.542,0 30,-13.458 30,-30 0,-16.541998 -13.458,-29.999998 -30,-29.999998 -16.542,0 -30,13.458 -30,29.999998 0,16.542 13.458,30 30,30 m 0,-93.999998 c -35.36,0 -64,28.64 -64,63.999998 0,21.04 22.66,66.61 41.01,100.07 12.5,22.77 22.99,39.93 22.99,39.93 0,0 10.5,-17.16 22.99,-39.94 18.35,-33.46 41.01,-79.02 41.01,-100.06 0,-35.359998 -28.64,-63.999998 -64,-63.999998 z m 0,85.999998 c -12.16,0 -22,-9.84 -22,-22 0,-12.16 9.84,-21.999998 22,-21.999998 12.16,0 22,9.839998 22,21.999998 0,12.16 -9.84,22 -22,22 z", id: "path8", style: {
  fill: "url(#InnerBorderGradient)"
} }), /* @__PURE__ */ React.createElement("path", { className: "cls-3", d: "m 128,55.457472 c 33.084,0 60,26.916 60,59.999998 0,15.642 -14.389,50.494 -40.517,98.137 -8.089,14.752 -15.397,27.233 -19.482,34.096 -4.083,-6.863 -11.389,-19.342 -19.484,-34.087 C 82.389,165.96147 68,131.10547 68,115.45647 68,82.372472 94.916,55.456472 128,55.456472 m 0,86.000998 c 14.336,0 26,-11.664 26,-26 0,-14.336 -11.664,-25.999998 -26,-25.999998 -14.336,0 -26,11.663998 -26,25.999998 0,14.336 11.664,26 26,26 m 0,-89.999998 c -35.36,0 -64,28.64 -64,63.999998 0,21.04 22.66,66.61 41.01,100.07 12.5,22.77 22.99,39.93 22.99,39.93 0,0 10.5,-17.16 22.99,-39.94 18.35,-33.46 41.01,-79.02 41.01,-100.06 0,-35.359998 -28.64,-63.999998 -64,-63.999998 z m 0,85.999998 c -12.16,0 -22,-9.84 -22,-22 0,-12.16 9.84,-21.999998 22,-21.999998 12.16,0 22,9.839998 22,21.999998 0,12.16 -9.84,22 -22,22 z", id: "path9", style: {
  fill: "url(#linear-gradient)"
} }));
export default SvgLogo;
