import * as React from "react";

export interface ErrorProps {
	code?: number,
	title?: string,
	body?: JSX.Element
}
export function Error({code, title, body}: ErrorProps) {
	return <main>
		<h1>{code}: <i>{title}</i></h1>
		{body && <>
			<hr />
			{body}
		</>}
	</main>
}
