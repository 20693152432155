import * as React from "react";
import LoadingSpinner from "src/components/loading";
import { GuessMap } from "src/presenters/mapPresenter";

export function HampusFantasticTest(props) {
	return (
	<main>
		<h1>Hampus' Fantastic Test Page</h1>
		<hr />
		<GuessMap model={props.model} isDebug={true}/>
	</main>
	)
}
